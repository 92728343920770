.loading {
    font-weight: bold;
    display:inline-block;
    font-family: monospace;
    font-size: 25px;
    clip-path: inset(0 3ch 0 0);
    animation: l 5s steps(4) infinite;
    color: #fff;
    opacity: 0.6;
}

@keyframes l {
    to {
        clip-path: inset(0 -1ch 0 0)
    }
}