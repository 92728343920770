.state {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 170px;
    height: 20px;
    padding: 5px;
    border-radius: 20px;
    backdrop-filter: blur(20px);
    background: linear-gradient(90deg, rgba(104, 95, 169, 0.2) 20%, rgba(104, 95, 169, 1) 30%, rgba(104, 95, 169, 1) 70%, rgba(104, 95, 169, 0.2) 80%);
    background-size: 200% 100%;
    background-position: 100% 0%;
    transition: background-position 1.5s;
}

.state > span {
    font-size: 14px;
    font-weight: 500;
    transition: color 1.5s;
}