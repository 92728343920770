.modalBottom {
    display: none;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 99;
    width: 100vw;
    height: 100vh;
    background: rgba(39, 35, 76, 0.5);
    backdrop-filter: blur(5px);
}

.modalBottom > section {
    display: flex;
    flex-direction: column;
    gap: 1vh;
    width: 100vw;
    max-width: 450px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 30px 30px 30px 30px;
    background-color: rgb(119, 135, 182, 0.3);
    box-shadow: 0 -5px 10px 5px rgba(0, 0, 0, 0.1);
    /* 팝업이 열릴때 스르륵 열리는 효과 */
    animation: modal-show 0.6s;
    overflow: hidden;
}

.modalBottom > section > header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    margin: 0 10%;
    background-color: transparent;
    font-size: 1.2em;
    color: white; /* #27234C, rgb(39, 35, 76) */
    font-weight: 900;
    padding-top: 12%;
    padding-bottom: 5%;
}

.modalBottom button {
    outline: none;
    cursor: pointer;
    border: 0;
}

.modalBottom > section > header button {
    text-align: center;
    font-size: 10vw;
    color: rgba(255, 255, 255, 0.5);
    background-color: transparent;
}

.modalBottom > section > main {
    padding: 0 10% 24px 10%;
    color: white;
    font-size: 16px;
    white-space: pre-line;
}

.modalBottom > section > main > img {
    width: 100%;
    object-fit: cover;
    margin-top: 24px;
}

.modalBottom > section > footer {
    padding: 0 10% 16% 10%;
    margin-top: 10%;
    text-align: right;
}

.modalBottom > section > footer button {
    background: white;
    color: #27234C;
    box-shadow: none;
    border-radius: 30px;
    font-size: 100%;
    width: 100%;
    height: 5vh;
}

.modalBottom.openModal {
    display: flex;
    /* 팝업이 열릴때 스르륵 열리는 효과 */
    opacity: 1;
    animation: modal-bg-show 0.6s;
}

@keyframes modal-show {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes modal-bg-show {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}