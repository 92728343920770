@font-face {
  font-family: 'NanumSquareRound';
  src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_two@1.0/NanumSquareRound.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

body {
  position: fixed;
  overflow-x: hidden;
  overflow-y: hidden;
  width: 100%;
  height: 100%;
}

body {
  margin: 10%;
  font-family: 'NanumSquareRound';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-user-select:none;
  -moz-user-select:none;
  -ms-user-select:none;
  user-select:none;
  -webkit-touch-callout:none;
  -webkit-tap-highlight-color:rgba(0,0,0,0);
  overscroll-behavior-x: none;
  -khtml-user-select: none;
  touch-action: none;
  height: 80vh;
  width: 80%;
  background: linear-gradient(180deg, #28234F 0%, #685FA9 65.1%, #7787B6 85.42%, #89B7C6 100%) fixed;
}

img {
  -webkit-touch-callout:none;
  -webkit-tap-highlight-color:rgba(0,0,0,0);
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-select: none;
}

code {
  font-family: 'NanumSquareRound';
}
