.modal {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99;
  width: 100vw;
  height: 100vh;
  background: rgba(39, 35, 76, 0.5);
  backdrop-filter: blur(5px);
}

.modal > section {
  width: 80%;
  max-width: 450px;
  margin: 0 auto;
  border-radius: 30px;
  background-color: #fff;
  box-shadow: 0 5px 10px 5px rgba(0, 0, 0, 0.1);
  /* 팝업이 열릴때 스르륵 열리는 효과 */
  animation: modal-show 0.3s;
  overflow: hidden;
}

.modal > section > header {
  position: relative;
  padding: 24px 64px 16px 24px;
  background-color: rgba(119, 135, 182, 0.5); /* #7787B6, rgb(119, 135, 182) */
  color: #27234C; /* #27234C, rgb(39, 35, 76) */
  font-size: 16px;
  font-weight: 700;
}

.modal button {
  outline: none;
  cursor: pointer;
  border: 0;
}

.modal > section > header button {
  position: absolute;
  top: 18px;
  right: 20px;
  width: 30px;
  font-size: 24px;
  font-weight: 700;
  text-align: center;
  color: rgba(39, 35, 76, 0.5);
  background-color: transparent;
}

.modal > section > main {
  padding: 24px;
  color: #27234C;
  font-size: 16px;
  white-space: pre-line;
}

.modal > section > footer {
  padding: 0 24px 24px 24px;
  text-align: right;
}

.modal > section > footer button {
  margin-left: 16px;
  padding: 6px;
  /* padding: 10px 16px 9px 16px;
  color: #fff;
  background-color: #7787B6; */
  color: #7787B6;
  background-color: transparent;
  border-radius: 20px;
  font-size: 16px;
  font-weight: 700;
}

.modal.openModal {
  display: flex;
  align-items: center;
  /* 팝업이 열릴때 스르륵 열리는 효과 */
  animation: modal-bg-show 0.3s;
}

@keyframes modal-show {
  from {
    opacity: 0;
    margin-top: -50px;
  }
  to {
    opacity: 1;
    margin-top: 0;
  }
}

@keyframes modal-bg-show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}